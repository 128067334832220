<template>
    <div class="home">
        <div class="home__arte-promocao">
            <a @click="() => router.push({ name: 'Premios' })">
                <img class="arte-g" :src="state.promocao.imagem" :alt="state.promocao.titulo">
            </a>
        </div>
        <div class="home__conteudo">
            <div class="bt bt--destaque" v-if="user == null || !user.nome || (user && !user.confirmacao_sms)">
                <a @click="() => router.push({ name: 'Cadastro' })">Participe</a>
            </div>
            <div class="bt bt--destaque" v-if="user && user !== null && user.confirmacao_sms">
                <a type="button" @click="() => router.push({ name: 'FormaPagto' })"> Participe </a>
            </div>
            <div class="bt">
                <a @click="() => router.push({ name: 'Premios' })">Veja os prêmios</a>
            </div>
            <p>Aqui na Rede America Plus você interage com a nossa programação, assiste aos seus artistas preferidos e ainda concorre a prêmios todos os dias.</p>
            <div class="bt">
                <a @click="() => router.push({ name: 'ComoParticipar' })">Veja o vídeo <span>- Como participar?</span></a>
            </div>
            <div class="bt">
                <a @click="() => router.push({ name: 'Duvidas' })">Dúvidas? <span class="caixa-alta">- FAQ</span></a>
            </div>
            <div class="bt">
                <a @click="() => router.push({ name: 'FaleConosco' })">Fale conosco <span>- Precisa de ajuda?</span></a>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'

export default {
  setup () {
    const user = JSON.parse(window.localStorage.getItem('user'))
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      depoimentos: {},
      promocao: {}
    })

    async function getDepoimentos () {
      try {
        state.isLoading = true
        const { data, errors } = await services.depoimentos.index()

        if (!errors) {
          state.depoimentos = data
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Depoimentos não encontrado')
        }

        if (errors.status === 401) {
          toast.error('Depoimentos Inválidos')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os Depoimentos!')
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getDepoimentos()

    async function getPromocao () {
      try {
        state.isLoading = true
        const { data, errors } = await services.promocao.index()

        if (!errors) {
          state.promocao = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401) {
          toast.error('Promoção não encontrada')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar a Promoção!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPromocao()

    return {
      router,
      state,
      user
    }
  }
}
</script>
